import React, { useState } from 'react';
import './RemoveModel.css'

interface RemoveModelProps {
    deleteAction(id: string): void;
    modelId: string;
  }

const RemoveModel: React.FC<RemoveModelProps> = ({ deleteAction, modelId }) => {
    const [confirm, setConfirm] = useState(false);
    const [show, setShow] = useState(false);
    if (modelId === '') {
        return null;
    }
    
    const showIcon = show ? 'lni-chevron-up' : 'lni-chevron-down';
    return (
        <div className="RemoveModel-main">
            <div className="RemoveModel-title" onClick={() => setShow(!show)}>
                <h2>Advanced</h2>
                <i className={showIcon}></i>
            </div>
            {
                show ? 
                (
                    <div>
                        <p>Remove your model and corresponding training data by pressing button below:</p>
                        {
                            confirm === false 
                                ? <button type="submit" onClick={() => setConfirm(true)}>Remove</button>
                                : <button type="submit" onClick={() => deleteAction(modelId)}>Confirm remove?</button>
                        }    
                    </div>
                ) : null
            }
            
        </div>
    )
}

export default RemoveModel;