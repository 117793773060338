import React from 'react'
import './TopBar.css'
import icon from './icon.svg';
import logo from '../shared/logo.png';
import Activation from './Activation';

interface TopBarProps {
    displayName: string;
    logout(): void;
    navAction(route: string): void;
    activationAction(code: string): void;
    errorMessage: string;
    activationRequired: boolean;
    activationStatus: string;
  }

const TopBar: React.FC<TopBarProps> = ({ displayName, logout, navAction, errorMessage, activationRequired, activationStatus, activationAction }) => {
    return (
        <div className="TopBar-main">
            {
                errorMessage !== '' 
                ? (
                    <div className="TopBar-error">
                        <h2>{errorMessage}</h2>
                    </div>
                ) : null
            }
            {
                activationRequired
                ? (
                    <div className="TopBar-error">
                        <Activation status={activationStatus} activationAction={activationAction} />
                    </div>
                ) : null
            }            
            <div className="TopBar-logo">
                <img className="TopBar-icon" src={logo} alt="logo" />
                <span className="TopBar-name">Kellar</span>
            </div>
            <div className="TopBar-routes">
                <span onClick={() => navAction('/')}>Models</span>
                <span>Help</span>
            </div>
            <div className="TopBar-right">
                <span className="TopBar-user">{displayName}</span>
                <i className="lni-exit TopBar-exit" onClick={() => logout()}></i>
            </div>
        </div>
    )
}

export default TopBar;