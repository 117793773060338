import React from 'react'
import Dropzone from 'react-dropzone'
import Loader from '../shared/Loader';
import { UPLOAD_STARTED, UPLOAD_TRAINING } from '../../constants/status';
import './ModelStatus.css'

interface ModelStatusProps {
    status: string;
}

const ModelStatus: React.FC<ModelStatusProps> = ({ status }) => {
    const statusText = status === 'TRAINING_FINISHED' ? 'Model ready' : 'Training....';
    return (
        <div className="ModelStatus-main">
            <span className="ModelStatus-label">Status: </span>
            {
                status === 'TRAINING_FINISHED'
                ? 
                    (
                        <div className="ModelStatus-status">
                            <span className="ModelStatus-statustext">{statusText}</span>
                            <i className="lni-check-mark-circle ModelStatus-ready"></i>
                        </div>

                    )
                :
                    (
                        <div className="ModelStatus-status">
                            <Loader color="#FF008C" width={15} height={15} />
                            <span className="ModelStatus-statustext">{statusText}</span>
                        </div>
                    )
            }
            
            
        </div>
    )
}

export default ModelStatus;