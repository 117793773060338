import React from 'react'
import Upload from './Upload';
import ModelStatus from './ModelStatus';
import './TrainingHandler.css';

interface TrainingHandlerProps {
    uploadStatus: string;
    trainingStatus: string;
    trainingData: string;
    modelId: string;
    uploadError: string;
    uploadAction(file: any): void;
}

const TrainingHandler: React.FC<TrainingHandlerProps> = ({ uploadStatus, trainingStatus, trainingData, uploadAction, modelId, uploadError }) => {
    return (
        modelId ? (
            <div className="TrainingHandler-main">
            <h2>Training</h2>
            {
                trainingStatus === 'TRAINING_FINISHED' || trainingStatus === 'TRAINING_STARTED'
                ? 
                    (
                        <div>
                            
                            <ModelStatus status={trainingStatus} />
                            <span className="ModelStatus-label">Training data: </span><span>{trainingData}</span>
                        </div>
                    )
                : <Upload uploadAction={uploadAction} uploadStatus={uploadStatus} uploadError={uploadError} />
            }
            
        </div>
        ) : null
    )
}

export default TrainingHandler;