const config = {
    apiKey: "AIzaSyCQ-8ZVVSgv9y4IXkuMwXH7hF2MHryw5CI",
    authDomain: "kellar-ai-ab346.firebaseapp.com",
    databaseURL: "https://kellar-ai-ab346.firebaseio.com",
    projectId: "kellar-ai-ab346",
    storageBucket: "",
    messagingSenderId: "753532214600",
    appId: "1:753532214600:web:18597582680bfc7527677d"
};

export default config;