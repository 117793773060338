import { LOAD_ALL_MODELS } from '../constants/actionTypes';

const initialState = {
    models: [],
};
  
  const allModels = (state = initialState, action) => {
    switch (action.type) {
        case LOAD_ALL_MODELS: {
            const { models } = action.payload;
            return Object.assign({}, state, { models });
        }
        default:
            return state;
    }
  };
  
  export default allModels;
  