import React from 'react'
import logo from './logo.png';

interface LogoProps {
    color: string
    width: number
    height: number
}

const Logo: React.FC<LogoProps> = ({ color, width, height }) => {
    return (
        <img src={logo} />
        // <svg width={`${width}px`} height={`${height}px`} fill={color} viewBox="0 0 77.303581 77.240305">
        //     <g
        //         transform="translate(-83.689996,-77.599226)"
        //         id="layer1">
        //         <path
        //             id="path905"
        //             d="m 114.05643,154.60141 c -15.503904,-1.17274 -26.215075,-4.42738 -29.468,-8.95398 -1.19617,-1.66453 -1.198084,-3.96633 -0.0047,-5.63647 1.853914,-2.59451 6.571976,-4.94137 13.262493,-6.597 3.424397,-0.84741 3.424397,-0.84741 7.056337,-12.38483 1.99757,-6.34559 4.3899,-13.91868 5.31629,-16.8291 0.92639,-2.91041 2.47752,-7.851512 3.44695,-10.980202 2.06539,-6.66578 3.05581,-8.69172 5.43658,-11.12073 4.66051,-4.75495 11.52542,-5.89398 16.18238,-2.68501 3.48751,2.40314 5.98871,6.02134 4.54533,6.57521 -0.23104,0.0887 -1.60162,-0.23342 -3.04573,-0.71573 -4.55553,-1.5215 -7.31139,-1.15797 -7.72761,1.01935 -0.12505,0.65418 1.61783,6.66283 4.58023,15.790452 0.56675,1.74625 1.93609,6.09203 3.04297,9.65729 1.10689,3.56526 2.58555,8.26823 3.28592,10.45104 0.70036,2.18281 1.71885,5.40148 2.26332,7.1526 0.98994,3.18386 0.98994,3.18386 4.4664,4.06155 17.37974,4.38782 19.20018,12.6811 3.89545,17.74633 -8.25134,2.73085 -25.05666,4.31744 -36.53462,3.44923 z m 18.65313,-7.51858 c 6.6532,-1.0653 13.09687,-3.70848 13.09687,-5.37231 0,-0.68945 -1.60817,-5.66689 -1.8024,-5.57861 -6.25727,2.84394 -10.73434,3.80091 -19.09968,4.08254 -9.64162,0.32459 -16.16494,-0.60863 -22.21095,-3.17748 -1.46009,-0.62037 -2.29551,-0.85153 -2.40969,-0.66678 -0.556273,0.90007 -1.641924,5.36595 -1.400598,5.76144 1.271518,2.08378 8.769698,4.59992 15.834778,5.31361 3.62264,0.36595 14.85597,0.13967 17.99167,-0.36241 z"
        //         />
        //     </g>
        // </svg>
    )
}

export default Logo;

