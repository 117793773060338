import React, { useState } from 'react';
import ModelRow from './ModelRow';
import './ModelList.css'

interface ModelListProps {
    models: Array<any>;
    selectAction(id: string): void; 
}

const PAGE_SIZE = 5;

const ModelList: React.FC<ModelListProps> = ({ models, selectAction }) => {
    const [page, setPage] = useState(0);
    
    const start = page * PAGE_SIZE;
    const end = start + PAGE_SIZE;

    const modelPage = models.sort((a, b) => {
        return +new Date(b.created_at) - +new Date(a.created_at);
    }).slice(start,end);

    const nextPage = () => {
        if ((page + 1) * PAGE_SIZE < models.length) {
            setPage(page + 1);
        }
    }

    const prevPage = () => {
        if (page > 0) {
            setPage(page - 1);
        }
    }

    const prevClass = page < 1 ? 'ModelList-paginate ModelList-paginate-inactive' : 'ModelList-paginate';
    const nextClass = (page + 1) * PAGE_SIZE < models.length ? 'ModelList-paginate' : 'ModelList-paginate ModelList-paginate-inactive';
    return (
        <div className="ModelList-main">
            {
                modelPage.map((m) => {
                    return <ModelRow key={m.model_id} name={m.name} description={m.description} icon={m.icon} id={m.model_id} selectAction={selectAction} />
                })
            }
            {
                models.length > 0 ? 
                (
                    <div className="ModelList-more">
                        <div className={prevClass} onClick={() => prevPage()}>
                            <i className="lni-chevron-left"></i>
                            <span>Prev</span>
                        </div>
                        <div className={nextClass} onClick={() => nextPage()}>
                            <span>Next</span>     
                            <i className="lni-chevron-right"></i>                  
                        </div>
                    </div>
                         
                )
                : <span />
            }
            
        </div>
    )
}

export default ModelList;