import { UPDATE_MODEL_STATUS, UPDATE_UPLOAD_STATUS, UPLOAD_ERROR, ERROR_MESSAGE } from '../constants/actionTypes';
import { UPLOAD_READY } from '../constants/status';

const initialState = {
    modelCreationStatus: 'IDLE',
    uploadStatus: UPLOAD_READY,
    uploadError: '',
    error: '',
  };
  
  const ui = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_MODEL_STATUS: {
            return Object.assign({}, state, { modelCreationStatus: action.payload.status });
        }
        case UPDATE_UPLOAD_STATUS: {
            return Object.assign({}, state, { uploadStatus: action.payload.status });
        }
        case UPLOAD_ERROR: {
            return Object.assign({}, state, { uploadError: action.payload.error });
        }
        case ERROR_MESSAGE: {
            return Object.assign({}, state, { error: action.payload.error});
        }
        default:
            return state;
    }
  };
  
  export default ui;
  