import React from 'react';
import ReactDOM from 'react-dom';
import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import { BrowserRouter as Router, Route } from "react-router-dom";
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import { reducer as formReducer } from 'redux-form';

import { createBrowserHistory } from 'history';
import { get } from 'lodash';
import './index.css';
import App from './App';
import Model from './views/Model';
import Main from './views/Main';
import Login from './views/Login';
import Header from './components/Header/Header';
import ui from './reducers/ui';
import model from './reducers/model';
import allModels from './reducers/allModels';
import user from './reducers/user';
import { loadUser, loadTeamInfo, updateActivationRequired } from './actions/userActions';
import { generalError } from './actions/uiActions';
import icon from './icon.svg';
import { checkUser } from './utils/api';
import * as serviceWorker from './serviceWorker';
import firebase from "firebase/app";
import "firebase/auth";
import {
  FirebaseAuthProvider,
  FirebaseAuthConsumer,
  IfFirebaseAuthed,
  IfFirebaseAuthedAnd
} from "@react-firebase/auth";
import config from "./config";

const reducers = combineReducers({
  ui,
  model,
  allModels,
  user,
  form: formReducer,
});

const w : any = window as any;
const devtools: any = w.devToolsExtension ? w.devToolsExtension() : (f:any)=>f;

const store = createStore(
    reducers,
    compose(
        applyMiddleware(thunk),
        devtools,
    ),
);


export const history = createBrowserHistory();

// ReactDOM.render(<App />, document.getElementById('root'));
ReactDOM.render(
    <Provider store={store}>
      <FirebaseAuthProvider {...config} firebase={firebase}>
        <FirebaseAuthConsumer>
            { ({ isSignedIn, providerId, user }) => {
              if (isSignedIn === true) {
                store.dispatch(loadUser(user));
                const userId = get(user, 'uid', '')
                checkUser(userId).then((result) => {
                  if (result.status === 'success') {
                    const apiKey = get(result, 'model.api_key', '')
                    store.dispatch(loadTeamInfo({ apiKey }));
                  } else {
                    console.log('Unknown user!')
                    store.dispatch(updateActivationRequired({ required: true }));
                    // store.dispatch(generalError({ error: 'Activation required' }));
                  }

                }).catch((error) => {
                  console.log(error);
                  store.dispatch(generalError({ error: 'Authentication error' }));
                });
                return (
                  <Router>
                  <div style={{ height: '100%' }}>
                    <Header />
                    <Route exact path="/" component={Main} />    
                    <Route path="/model" component={Model} />
                  </div>
                </Router>
                )
              } else {
                return (
                  <Login
                    googleLogin={() => {
                        const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
                        firebase.auth().signInWithPopup(googleAuthProvider).then(async (user) => {
                          const userId = get(user, 'user.uid', '')
                          const result = await checkUser(userId);
                        }).catch((error) => console.log(error))

                    }}
                  />
                )
              }
            }}
          </FirebaseAuthConsumer>
      </FirebaseAuthProvider>
    </Provider>,
    document.getElementById('root')
  );
  

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
