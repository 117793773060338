import { LOAD_MODEL, UPDATE_MODEL, RESET_MODEL } from '../constants/actionTypes';

const initialState = {
    modelId: '',
    name: '',
    description: '',
    userId: '',
    trainingStatus: 'IDLE',
    trainingData: '',
  };
  
  const model = (state = initialState, action) => {
    switch (action.type) {
        case LOAD_MODEL: {
            const { model_id, name, description, user_id, training_status, training_data } = action.payload;
            return Object.assign({}, state, {
                modelId: model_id,
                userId: user_id,
                trainingStatus: training_status,
                trainingData: training_data,
                description,
                name,
            });
        }
        case UPDATE_MODEL: {
            return Object.assign({}, state, action.payload);
        }
        case RESET_MODEL: {
            return initialState;
        }
        default:
            return state;
    }
  };
  
  export default model;
  