import { connect } from 'react-redux';
import { createModel, updateExistingModel } from '../../actions/ModelActions';
import { getModelCreationStatus} from '../../selectors/uiSelectors';
import { getName, getDescription, getModelId } from '../../selectors/modelSelectors';
import Form from './Form';

const mapStateToProps = (state) => {
  return {
    attendees: 'BX',
    modelStatus: getModelCreationStatus(state),
    modelId: getModelId(state),
    name: getName(state),
    description: getDescription(state),
    initialValues: {
      description: getDescription(state),
      name: getName(state),
    }
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    submitAction: (form, modelId) => {
      if (modelId) {
        dispatch(updateExistingModel(modelId, form))
        // console.log('Update model!')
      } else {
        dispatch(createModel(form))
      }
    },
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Form);