import * as React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Link,
  RouteComponentProps
} from "react-router-dom";
import queryString from 'query-string';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk'
import ModelForm from '../components/ModelForm/ModelForm';
import Training from '../components/Training/Training';
import Synthesize from '../components/Synthesize/Synthesize';
import Remove from '../components/Remove/Remove';
import { getModel } from '../actions/ModelActions';
import './Model.css';

interface ModelProps extends RouteComponentProps<any> {
  getModel: any;
}

class Model extends React.Component<ModelProps> {
    componentDidMount() {
      if('location' in this.props) {
        const params = queryString.parse(this.props.location.search)
        const { id } = params;
        if(id) {
          this.props.getModel(id);
        }
      }
      // console.log(this.props)


    }
    render () {
      return (
        <div>
          <div className="Model-main">
            <div className="Model-borders">
              <ModelForm customText="2222"/>
              <Training />

              <Synthesize />
              <Remove />
            </div>

          </div>
        </div>

      );
    }
  }

  const mapDispatchToProps = (dispatch: Dispatch): any => {
    return {
      getModel: (id: string) => dispatch<any>(getModel({ id })),
    }
  };

  export default connect(null, mapDispatchToProps)(Model);
