import * as React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Link,
  RouteComponentProps
} from "react-router-dom";
import queryString from 'query-string';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import PreTrained from '../components/Models/PreTrained';
import YourModels from '../components/Models/YourModels';
import { getAllModels } from '../actions/AllModelsActions';
import './Main.css';

interface MainProps extends RouteComponentProps<any> {
  getModels: any;
}

class Main extends React.Component<MainProps> {
    componentDidMount() {
        this.props.getModels();
    }
    render () {
        return (
        <div>
          <div className="Main-main">
            <h1>Explore pre-trained models</h1>
            <PreTrained />
            <div className="Main-yourModels">
              <h1>Your models</h1>
              <button type="submit" onClick={() => this.props.history.push('/model')}>Create new</button>
            </div>
            <YourModels />
            
          </div>
        </div>

        );
    }
  }

  const mapDispatchToProps = (dispatch: Dispatch): any => {
    return {
      getModels: (id: string) => dispatch<any>(getAllModels()),
    }
  };

  export default connect(null, mapDispatchToProps)(Main);
