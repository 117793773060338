import { createAction } from 'redux-actions';
import { createModelApi, uploadDataApi, trainingStatusApi, getModelApi, deleteModelApi, updateModelApi } from '../utils/api';
import { updateModelStatus, updateUploadStatus, uploadError } from './uiActions';
import { getModelId } from '../selectors/modelSelectors';
import { UPLOAD_STARTED, UPLOAD_TRAINING } from '../constants/status';
import { LOAD_MODEL, UPDATE_MODEL, RESET_MODEL } from '../constants/actionTypes';
import { async } from 'q';

export const loadModel = createAction(LOAD_MODEL);
export const updateModel = createAction(UPDATE_MODEL);
export const resetModel = createAction(RESET_MODEL);

export const createModel = (payload) => {
    return async (dispatch, getState) => {
      dispatch(updateModelStatus({ status: 'PROCESSING' }));
      const { name, description } = payload;
      try {
        const update = {
          user_id: 'johndoe',
          name,
          description,
        }
        const response = await createModelApi(update);
        const { model } = response;
        dispatch(loadModel(model));
      }
      catch(error) {
        console.log(error)
      }
  };
};

export const updateExistingModel = (modelId, update) => {
  return async (dispatch) => {
    // dispatch(updateModel(payload));
    try {
      await updateModelApi(modelId, update);
      dispatch(updateModel(update));
    } catch(error) {
      console.log(error)
    }
    
  }
}

export const uploadData = (payload) => {
  return async (dispatch, getState) => {
    const modelId = getModelId(getState());
    dispatch(updateUploadStatus({ status: UPLOAD_STARTED }));
    
    try {
      const response = await uploadDataApi(modelId, payload[0]);
      dispatch(updateUploadStatus({ status: UPLOAD_TRAINING }));
      dispatch(updateModel({ trainingStatus: 'TRAINING_STARTED' }));

      const interval = setInterval(async function(){
        const data = await trainingStatusApi(modelId);
        // console.log(data)
        dispatch(updateModel({ trainingStatus: data.training_status }));
        if (data.training_status === 'TRAINING_FINISHED') {
          dispatch(updateModel({ trainingStatus: data.training_status }));
          clearInterval(interval);
        } else if (data.training_status === 'TRAINING_DATA_ERROR') {
          dispatch(uploadError({ error: 'Unable to read training data'}));
          clearInterval(interval);
        }
      }, 4000);
    }
    catch(error) {
      console.log('Error uploading data');
    }
  }
}

export const getModel = (payload) => {
  return async (dispatch, getState) => {
    const { id } = payload;
    try {
      const response = await getModelApi(id);
      const { model } = response;
      dispatch(loadModel(model));
    }
    catch(error) {
      console.log(error)
    }
  }  
}

export const removeModel = (payload) => {
  return async (dispatch, getState) => {
    const { id } = payload;
    console.log('Should remove model: ' + id)
    try {
      const response = await deleteModelApi(id);
    } catch(error) {
      console.log(error)
    }
  }
}