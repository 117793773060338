import { createAction } from 'redux-actions';
import { getAllModelsApi } from '../utils/api';
import { LOAD_ALL_MODELS } from '../constants/actionTypes';

export const loadAllModels = createAction( LOAD_ALL_MODELS);

export const getAllModels = () => {
    return async (dispatch, getState) => {
      try {
        const response = await getAllModelsApi();
        const { models } = response;
        dispatch(loadAllModels({ models }));
        console.log(response)
      }
      catch(error) {
        console.log(error)
      }
    }  
  }