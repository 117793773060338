import React from 'react'
import './ModelRow.css'

interface ModelRowProps {
    name: string;
    description: string;
    icon: string;
    id: string;
    selectAction(id: string): void; 
}

const ModelRow: React.FC<ModelRowProps> = ({ name, description, icon, id, selectAction }) => {
    const rowIcon = icon ? icon : 'help';
    return (
        <div className="ModelRow-main" onClick={() => selectAction(id)}>
            {
                icon ?
                (
                    <div className="ModelRow-avatar">
                        <i className={`lni-${rowIcon} ModelRow-icon`}></i>
                    </div>
                ) :
                (
                    <div className="ModelRow-avatar">{name[0]}</div>
                )
            }
            <div className="ModelRow-text">
                <div className="ModelRow-name">{name}</div>
                <div className="ModelRow-description">{description}</div>
            </div>

        </div>
    )
}

export default ModelRow;