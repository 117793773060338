import firebase from "firebase/app";

const apiHost = process.env.REACT_APP_API_HOST || 'localhost';
const apiPort = process.env.REACT_APP_API_PORT || 3001;
const serverType = process.env.REACT_APP_SERVER_TYPE === 'HTTPS' ? 'https' : 'http';
const apiUrl = `${serverType}://${apiHost}:${apiPort}`;

async function getIdToken() {
  try {
    const idToken = await firebase.auth().currentUser.getIdToken(true);
    return idToken;
  } catch (error) {
    throw new Error(error);
  }
}

async function generateHeaders() {
  try {
    const token = await getIdToken();
    return {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }
  } catch (error) {
      throw new Error(error);
  }
}

export async function createModelApi(update) {
    const requestUrl = `${apiUrl}/models`;

    try {
        const headers = await generateHeaders();
        const request = {
            method: 'POST',
            body: JSON.stringify(update),
            headers,
        };

        const response = await fetch(requestUrl, request);
    
        if (response.ok) {
          const data = await response.json();
          return data;
        }
      } catch (error) {
        console.log(error)
        throw new Error(error);
      }    
}

export async function updateModelApi(modelId, update) {
  const requestUrl = `${apiUrl}/models/${modelId}/`;
  try {
      const headers = await generateHeaders();
      const request = {
          method: 'POST',
          body: JSON.stringify(update),
          headers,
      };

      const response = await fetch(requestUrl, request);
  
      if (response.ok) {
        const data = await response.json();
        return data;
      }
    } catch (error) {
      console.log(error)
      throw new Error(error);
    }    
}

export const objectUrlToBinary = (url) => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', url, true);
    xhr.responseType = 'blob';
    xhr.onload = function(e) {
      if (this.status == 200) {
        const blob = this.response;
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onload =  function(e){
          resolve(e.target.result);
        };
      } else {
        reject(e);
      }
    };
    xhr.send();
  });
}

export async function uploadDataApi(modelId, file) {
  const name = file.name;
  const objectUrl = URL.createObjectURL(file);
  const bin = await objectUrlToBinary(objectUrl);
  
  const requestUrl = `${apiUrl}/models/${modelId}/data/`;

  try {
      const headers = await generateHeaders();
      const request = {
        method: 'POST',
        body: JSON.stringify({
          file: bin,
          name,
        }),
        headers,
      }      
      const response = await fetch(requestUrl, request);
      if (response.ok) {
        return true;
      }
    } catch (error) {
        console.log(error)
        throw new Error(error);
    }    
}

export async function trainingStatusApi(modelId) {
  const requestUrl = `${apiUrl}/models/${modelId}/training_status/`;

  try {
      const headers = await generateHeaders();
      const request = {
          method: 'GET',
          headers,
      };

      const response = await fetch(requestUrl, request);
      if (response.ok) {
        const data = await response.json();
        return data;
      }
    } catch (error) {
        console.log(error)
        throw new Error(error);
    }    
}

export async function getModelApi(modelId) {
  const requestUrl = `${apiUrl}/models/${modelId}/`;

  try {
      const headers = await generateHeaders();
      const request = {
          method: 'GET',
          headers,
      };

      const response = await fetch(requestUrl, request);
      if (response.ok) {
        const data = await response.json();
        return data;
      }
    } catch (error) {
        console.log(error)
        throw new Error(error);
    }    
}

export async function getAllModelsApi() {
  const requestUrl = `${apiUrl}/models/`;

  try {
      const headers = await generateHeaders();
      const request = {
        method: 'GET',
        headers,
      };

      const response = await fetch(requestUrl, request);
      if (response.ok) {
        const data = await response.json();
        return data;
      }
    } catch (error) {
        console.log(error)
        throw new Error(error);
    }    
}

export async function checkUser(userId) {
  const token = await getIdToken();
  const requestUrl = `${apiUrl}/users/${userId}`;

  try {
    const headers = await generateHeaders();
    const request = {
      method: 'GET',
      headers,
    };

    const response = await fetch(requestUrl, request);
    if (response.ok) {
      const data = await response.json();
      return data;
    } else if (response.status === 404) {
      return {
        status: 'Not found',
      };
      // Disable this until user has code
      // const createRequest = {
      //   method: 'POST',
      //   headers: {
      //     Authorization: `Bearer ${token}`,
      //     'Content-Type': 'application/json'
      //   }
      // }
      // const response = await fetch(requestUrl, createRequest);
    } else {
      throw new Error('Unauthorized');
    }
  } catch (error) {
      console.log(error)
      throw new Error(error);
  } 
}

export async function createUser(userId, code) {
  const requestUrl = `${apiUrl}/users/${userId}`;

  try {
    const headers = await generateHeaders();
    
    const request = {
      method: 'POST',
      body: JSON.stringify({ code }),
      headers,
    };

    const response = await fetch(requestUrl, request);
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      throw new Error('Unauthorized');
    }
  } catch (error) {
      console.log(error)
      throw new Error(error);
  } 
}

export async function deleteModelApi(modelId) {

  const requestUrl = `${apiUrl}/models/${modelId}`;

  try {
    const headers = await generateHeaders();
    const request = {
      method: 'DELETE',
      headers,
    };

    const response = await fetch(requestUrl, request);

  } catch (error) {
      console.log(error)
      throw new Error(error);
  } 
}