import { createAction } from 'redux-actions';
import { get } from 'lodash';
import { LOAD_USER, LOAD_TEAM_INFO, UPDATE_ACTIVATION_STATUS, UPDATE_ACTIVATION_REQUIRED } from '../constants/actionTypes';
import { PROCESSING, ERROR } from '../constants/status';
import { createUser } from '../utils/api';
import { getUid } from '../selectors/userSelectors';

export const loadUser = createAction(LOAD_USER);
export const loadTeamInfo = createAction(LOAD_TEAM_INFO);
export const updateActivationStatus = createAction(UPDATE_ACTIVATION_STATUS);
export const updateActivationRequired = createAction(UPDATE_ACTIVATION_REQUIRED);

export const activateUser = (payload) => {
    return async (dispatch, getState) => {
        const { code } = payload;
        const userId = getUid(getState());
        dispatch(updateActivationStatus({ status: PROCESSING }));
        try {
            const response = await createUser(userId, code);
            const { user } = response;
            const apiKey = get(response, 'user.apiKey', '');
            dispatch(loadTeamInfo({ apiKey }));
            dispatch(updateActivationRequired({ required: false }));

        } catch(error) {
            console.log(error);
            dispatch(updateActivationStatus({ status: ERROR }));
        }
  };
};