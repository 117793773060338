import React, { useState, useEffect } from 'react';
import Dropzone from 'react-dropzone'
import Prism from 'prismjs';
import { UPLOAD_STARTED, UPLOAD_TRAINING } from '../../constants/status';
import './Options.css'
import '../../prism.css';

const apiHost = process.env.REACT_APP_API_HOST || 'localhost';
const apiPort = process.env.REACT_APP_API_PORT || 3001;

interface OptionsProps {
    status: string;
    modelId: string;
    apiKey: string;
}



const Options: React.FC<OptionsProps> = ({ status, modelId, apiKey }) => {
    const url = `https://${apiHost}:${apiPort}/synthesize/${modelId}?key=${apiKey}`
    useEffect(() => {
        Prism.highlightAll();
    });
    const [show, setShow] = useState(false);

    const copyClipboard = () => {
        const copyText = document.getElementById("apiUrl") as HTMLInputElement;
        setShow(true);
        if (copyText) {
            copyText.select();
            document.execCommand("copy");
        }
        const timer = setTimeout(() => {
            setShow(false);
        }, 2000);
        // clearTimeout(timer);        
    }
    const clipboardClass = !show ? 'Options-copy-notice' : 'Options-copy-notice'
    return (
        modelId && status === 'TRAINING_FINISHED' ? (
            <div className="Options-main">
                <h2>Synthesize data</h2>
                <div className="Options-label">REST API call</div>
                <p>Each model has a REST endpoint which can be used for synthesizing data. Copy the underlying API address with your API key and start generating data. </p>

                <div className="Options-api">
                    <input id="apiUrl" type="text" value={url} readOnly/>
                    <i className="lni-clipboard" onClick={() => copyClipboard()}></i>
                    {show ? <div className={clipboardClass}>Copied to clipboard!</div> : null}
                </div>
                <div className="Options-label">Client</div>
                <p>In addition, Javascript client is available for synthesizing data. Client is useful when you want to access the data directly from your code. Below you can find an example how to use the client:  </p>
                <pre>
                    <code className="language-javascript">
                    {`
import Kellar from 'kellar';

const secrets = {
    modelID: '${modelId}',
    apiKey: '${apiKey}'
};
const client = Kellar(secrets);
const yourData = client.generate();
                    `}
                    </code>
                </pre>
            </div>
        ) : null

    )
}

export default Options;