import { connect } from 'react-redux';
import { createModel, uploadData } from '../../actions/ModelActions';
import { uploadError } from '../../actions/uiActions';
import { getUploadStatus, getUploadError } from '../../selectors/uiSelectors';
import { getTrainingStatus, getTrainingData, getModelId } from '../../selectors/modelSelectors';
import TrainingHandler from './TrainingHandler';

const mapStateToProps = (state) => {
  return {
    uploadStatus: getUploadStatus(state),
    trainingStatus:  getTrainingStatus(state),
    trainingData: getTrainingData(state),
    modelId: getModelId(state),
    uploadError: getUploadError(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    uploadAction: (file) => {
        if (file.length > 0) {
          dispatch(uploadData(file));
        } else {
          dispatch(uploadError({ error: 'Illegal file type'}));
        }
    },
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(TrainingHandler);