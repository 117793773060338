import { connect } from 'react-redux';
import { createModel, uploadData } from '../../actions/ModelActions';
import { getUploadStatus} from '../../selectors/uiSelectors';
import { getAllModels } from '../../selectors/allModelsSelectors';
import { withRouter } from "react-router";
import ModelList from './ModelList';

const mapStateToProps = (state) => {
  return {
    models: getAllModels(state)
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    selectAction: (id) => {
      ownProps.history.push(`/model?id=${id}`)
    },
  }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ModelList));