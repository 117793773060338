import { connect } from 'react-redux';
import firebase from "firebase/app";
import "firebase/auth";
import { withRouter } from "react-router";
import { getModelId } from '../../selectors/modelSelectors';
import { removeModel } from '../../actions/ModelActions';
import RemoveModel from './RemoveModel';

const mapStateToProps = (state) => {
  return {
    modelId: getModelId(state),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    deleteAction: (id) => {
        dispatch(removeModel({ id }));
        ownProps.history.push('/')
    }
  }
};


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RemoveModel));