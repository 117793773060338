import React, { useState } from 'react';
import {
  Field,
  InjectedFormProps,
  reduxForm,
} from 'redux-form';
import Loader from '../shared/Loader';
import './Form.css'

interface CustomProps {
  customText: string;
  submitAction: any;
  modelStatus: string;
  modelId: string;
  name: string;
  description: string;
}

interface State {
  update: boolean;
}

class FormComponent extends React.Component<CustomProps & InjectedFormProps<{}, CustomProps>, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      update: false,
    };
  }
  render() {

    const { modelStatus, handleSubmit, pristine, reset, submitting, submitAction, modelId, name, description } = this.props;

    let buttonRow;
    if (modelStatus === "IDLE" && modelId === '') {
      buttonRow = (
        <div className="Form-buttons">
          <button type="submit" disabled={pristine || submitting}>
            Create model
            </button>
          <button className="button-outline" type="button" disabled={pristine || submitting} onClick={reset}>
            Clear
            </button>
        </div>
      )
    } else if (modelStatus !== "IDLE" && modelId === '') {
      buttonRow = (
        <div className="Form-loader">
          < Loader color="red" width={25} height={25} />
          <span>Creating model...</span>
        </div>
      )
    } else if (modelId !== '' && this.state.update) {
      buttonRow = (
        <div className="Form-buttons">
          <button type="submit" disabled={pristine || submitting} onClick={() => this.setState({ update: false })}>
            Update
          </button>
        </div>
      )
    } else {
      buttonRow = null;
    }

    const title = modelId === '' ? 'Create new model' : 'Edit model';

    if (modelId && !this.state.update) {
      return (
        <div className="Form-info">
          <div className="Form-edit" onClick={() => this.setState({ update: true })}>
            <span>Edit</span>
            <i className="lni-pencil"></i>
          </div>
          <h2>{name}</h2>
          <p>{description}</p>
        </div>
      )
    }
    return (
      // <div className="Form-main">
      <form onSubmit={handleSubmit((v) => submitAction(v, modelId))}>
        {
          modelId ? (
            <div className="Form-edit" onClick={() => this.setState({ update: false })}>
              <span>Close</span>
              <i className="lni-pencil"></i>
            </div>
          ) : null
        }

        <h2>{title}</h2>
        <div>
          <label>Name</label>
          <div>
            <Field
              name="name"
              component="input"
              type="text"
              placeholder="My awesome model"
            />
          </div>
        </div>
        <div>
          <label>Description</label>
          <div>
            <Field name="description" component="textarea" />
          </div>
        </div>

        {
          buttonRow
        }
      </form>
      // </div>

    )
  }
}

const Form = reduxForm<{}, CustomProps>({
  form: 'form',
  enableReinitialize: true,
})(FormComponent);

export default Form;