import * as React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Link,
  RouteComponentProps
} from "react-router-dom";
import Logo from '../components/shared/Logo';
import './Login.css';

interface LoginProps{
  googleLogin: any;
}

class Login extends React.Component<LoginProps> {
    render () {
        return (
          <div className="Login-main">
            <Logo color="#3bc8ff" height={160} width={160} />
            
            <div className="Login-text">
              <h1 className="Login-name">Kellar</h1>
              <h1 className="Login-name">Kellar</h1>
              <h1 className="Login-name">Kellar</h1>
            </div>
            {/* <div className="Login-name">Kellar</div> */}
            <div className="Login-subtitle">The AI powered faker</div>
            <button type="submit" className="button-outline" onClick={() => this.props.googleLogin()}>Login with Google</button>
          </div>
        );
    }
  }


  export default Login;
