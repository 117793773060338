import { get } from 'lodash';
import { LOAD_USER, LOAD_TEAM_INFO, UPDATE_ACTIVATION_STATUS, UPDATE_ACTIVATION_REQUIRED } from '../constants/actionTypes';
import { IDLE } from '../constants/status';

const initialState = {
    email: '',
    displayName: '',
    apiKey: '',
    uid: '',
    activationStatus: IDLE,
    activationRequired: false,
};
  
  const user = (state = initialState, action) => {
    switch (action.type) {
        case LOAD_USER: {
            const email = get(action.payload, 'email', '');
            const displayName = get(action.payload, 'displayName', '');
            const uid = get(action.payload, 'uid', '');
            return Object.assign({}, state, { email, displayName, uid });
        }
        case LOAD_TEAM_INFO: {
            const apiKey = get(action.payload, 'apiKey', '');
            return Object.assign({}, state, { apiKey });
        }
        case UPDATE_ACTIVATION_STATUS: {
            const { status } = action.payload;
            return Object.assign({}, state, { activationStatus: status });
        }
        case UPDATE_ACTIVATION_REQUIRED: {
            const { required } = action.payload;
            return Object.assign({}, state, { activationRequired: required });
        }
        default:
            return state;
    }
  };
  
  export default user;