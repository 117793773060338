import { connect } from 'react-redux';
import firebase from "firebase/app";
import "firebase/auth";
import { withRouter } from "react-router";
import { getDisplayName, getActivationRequired, getActivationStatus } from '../../selectors/userSelectors';
import { getErrorMessage } from '../../selectors/uiSelectors';
import { activateUser } from '../../actions/userActions';
import { resetModel } from '../../actions/ModelActions';

import TopBar from './TopBar';

const mapStateToProps = (state) => {
  return {
    displayName: getDisplayName(state),
    errorMessage: getErrorMessage(state),
    activationRequired: getActivationRequired(state),
    activationStatus: getActivationStatus(state),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    logout: () => {
      firebase.auth().signOut();
    },
    navAction: (route) => {
      ownProps.history.push(route)
      dispatch(resetModel());
    },
    activationAction: (code) => {
      // console.log(code);
      dispatch(activateUser({ code }));
    }
  }
};


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TopBar));