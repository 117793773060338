import { connect } from 'react-redux';
import { createModel, uploadData } from '../../actions/ModelActions';
import { getUploadStatus} from '../../selectors/uiSelectors';
import { getApiKey } from '../../selectors/userSelectors';
import { getTrainingStatus, getTrainingData, getModelId } from '../../selectors/modelSelectors';
import Options from './Options';

const mapStateToProps = (state) => {
  return {
    status: getTrainingStatus(state),
    modelId: getModelId(state),
    apiKey: getApiKey(state),
  };
};


export default connect(mapStateToProps)(Options);