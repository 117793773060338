import React, { useState } from 'react';
import './Activation.css'
import Loader from '../shared/Loader';
import { PROCESSING, ERROR } from '../../constants/status';
import icon from './icon.svg';

interface ActivationProps {
    status: string;
    activationAction(code: string): void;
  }

const Activation: React.FC<ActivationProps> = ({ status, activationAction }) => {
    const [code, setCode] = useState({ [0]: '', [1]: '', [2]: '', [3]: '' });

    const validateInput = () => {
        if (code[0] !== '' && code[1] !== '' && code[2] !== '' && code[3] !== '') {
            return true;
        }
        return false;
    }

    const activate = () => {
        if (validateInput()) {
            const codestring = code[0] + code[1] + code[2] + code[3];
            activationAction(codestring)
        }
    };

    // const renderStatus = () => {
    //     if (status === PROCESSING) {
    //         return (
                
    //         )
    //     }
    // }
    return (
        <div className="Activation-main">
            <h2>Enter your activation code: </h2>
            <div className="Activation-inputs">
                <input maxLength={1} onInput={(e: React.ChangeEvent<HTMLInputElement>)  => setCode(Object.assign({}, code, { [0]: e.target.value }))} />
                <input maxLength={1} onInput={(e: React.ChangeEvent<HTMLInputElement>) => setCode(Object.assign({}, code, { [1]: e.target.value }))} />
                <input maxLength={1} onInput={(e: React.ChangeEvent<HTMLInputElement>) => setCode(Object.assign({}, code, { [2]: e.target.value }))} />
                <input maxLength={1} onInput={(e: React.ChangeEvent<HTMLInputElement>) => setCode(Object.assign({}, code, { [3]: e.target.value }))} />
            </div>
            {
                status === ERROR ? (
                    <div className="Activation-error">
                        <div>Unable to verify your activation code.</div>
                        <div>Please email contact@kellar.ai for help.</div>
                    </div>
                ) : null
            }
            {
                status === PROCESSING ?
                (
                    <div className="Activation-processing">
                        <Loader color="#FF008C" width={25} height={25} />
                        <span>Processing ....</span>
                    </div>
                ) : <button type="submit" className="button-outline" onClick={activate} disabled={!validateInput()}>Activate</button>
            }

            
            
        </div>
    )
}

export default Activation;