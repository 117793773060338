import React, { useState } from 'react';
import Dropzone from 'react-dropzone'
import Loader from '../shared/Loader';
import { UPLOAD_STARTED, UPLOAD_TRAINING } from '../../constants/status';
import './Upload.css'
import Training from '../ModelForm/Training';

interface UploadProps {
    uploadAction(file: any): void;
    uploadStatus: string;
    uploadError: string;
}

enum TrainingLevel {
    Simple,
    Medium,
    Complex,
};

const Upload: React.FC<UploadProps> = ({ uploadAction, uploadStatus, uploadError }) => {
    let uploadText = '';
    const [files, setFiles] = useState([] as Array<File>);
    const [trainingLevel, setTrainingLevel] = useState(TrainingLevel.Simple);

    const onTraining = () => {
        uploadAction(files);
    }

    if (uploadStatus === UPLOAD_STARTED && uploadError === '') {
        return (
            <div className="Upload-main">
                <div className="Upload-loader">
                    <Loader color="#FF008C" width={25} height={25} />
                    <span>Uploading training data...</span>
                </div>                
            </div>
        )
    } else if (files.length > 0) {
        const simpleSelectionClass = trainingLevel === TrainingLevel.Simple ? 'Upload-button Upload-button-selected' : 'Upload-button';
        const mediumSelectionClass = trainingLevel === TrainingLevel.Medium ? 'Upload-button Upload-button-selected' : 'Upload-button';
        const complexSelectionClass = trainingLevel === TrainingLevel.Complex ? 'Upload-button Upload-button-selected' : 'Upload-button';

        return (
            <div className="Upload-training">
                <p>Select the level of preferred training complexity. More complex training takes more time.</p>
                <div className="Upload-radiobuttons">
                    <div className="Upload-option">
                        <div className={simpleSelectionClass} onClick={() => setTrainingLevel(TrainingLevel.Simple)} />
                        <span>Simple</span>
                    </div>
                    <div className="Upload-option" onClick={() => setTrainingLevel(TrainingLevel.Medium)} >
                        <div className={mediumSelectionClass} />
                        <span>Medium</span>
                    </div>
                    <div className="Upload-option" onClick={() => setTrainingLevel(TrainingLevel.Complex)} >
                        <div className={complexSelectionClass} />
                        <span>Skynet</span>
                    </div>
                </div>
                <button onClick={() => onTraining()}>Start training</button>
            </div>
        );
    } else {
        return (
            <div className="Upload-main">
                <Dropzone onDrop={acceptedFiles => setFiles(acceptedFiles)} accept='.csv'>
                    {({getRootProps, getInputProps, rejectedFiles}) => (
                        <div className="Upload-drop" {...getRootProps()}>
                            {
                                uploadError !== ''
                                    ? <div className="Upload-error">{uploadError}</div>
                                : null
                            }                                  
                            <input {...getInputProps()} />
                            <p>Upload training data</p>
                            <svg xmlns="http://www.w3.org/2000/svg" width="25px" height="25px" fill="#d1d1d1" viewBox="0 0 24 24"><path d="M8.71,7.71,11,5.41V15a1,1,0,0,0,2,0V5.41l2.29,2.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42l-4-4a1,1,0,0,0-.33-.21,1,1,0,0,0-.76,0,1,1,0,0,0-.33.21l-4,4A1,1,0,1,0,8.71,7.71ZM21,12a1,1,0,0,0-1,1v6a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1V13a1,1,0,0,0-2,0v6a3,3,0,0,0,3,3H19a3,3,0,0,0,3-3V13A1,1,0,0,0,21,12Z"/></svg>
                        </div>
                    )}
                </Dropzone>
            </div>
        )
    }
    // return (
    //     <div className="Upload-main">
    //         {
    //             uploadError === '' && (uploadStatus === UPLOAD_STARTED || uploadStatus === UPLOAD_TRAINING)
    //                 ?
    //                     (
    //                         <div className="Upload-loader">
    //                             <Loader color="#FF008C" width={25} height={25} />
    //                             <span>{uploadText}</span>
    //                         </div>
    //                     )
    //                 :
    //                     (
    //                         <Dropzone onDrop={acceptedFiles => uploadAction(acceptedFiles)} accept='.csv'>
    //                         {({getRootProps, getInputProps, rejectedFiles}) => (
    //                             <div className="Upload-drop" {...getRootProps()}>
    //                                 {
    //                                     uploadError !== ''
    //                                     ? <div className="Upload-error">{uploadError}</div>
    //                                     : null
    //                                 }                                  
    //                                 <input {...getInputProps()} />
    //                                 <p>Upload training data</p>
    //                                 <svg xmlns="http://www.w3.org/2000/svg" width="25px" height="25px" fill="#d1d1d1" viewBox="0 0 24 24"><path d="M8.71,7.71,11,5.41V15a1,1,0,0,0,2,0V5.41l2.29,2.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42l-4-4a1,1,0,0,0-.33-.21,1,1,0,0,0-.76,0,1,1,0,0,0-.33.21l-4,4A1,1,0,1,0,8.71,7.71ZM21,12a1,1,0,0,0-1,1v6a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1V13a1,1,0,0,0-2,0v6a3,3,0,0,0,3,3H19a3,3,0,0,0,3-3V13A1,1,0,0,0,21,12Z"/></svg>
    //                             </div>
    //                         )}
    //                         </Dropzone>
    //                     )
    //         }

    //     </div>
    // )
}

export default Upload;