export const UPDATE_MODEL_STATUS = 'UPDATE_MODEL_STATUS';
export const UPDATE_UPLOAD_STATUS = 'UPDATE_UPLOAD_STATUS';
export const LOAD_MODEL = 'LOAD_MODEL';
export const UPDATE_MODEL = 'UPDATE_MODEL';
export const LOAD_ALL_MODELS = 'LOAD_ALL_MODELS';
export const LOAD_USER = 'LOAD_USER';
export const LOAD_TEAM_INFO = 'LOAD_TEAM_INFO';
export const UPLOAD_ERROR = 'UPLOAD_ERROR';
export const ERROR_MESSAGE = 'ERROR_MESSAGE';
export const UPDATE_ACTIVATION_STATUS = 'UPDATE_ACTIVATION_STATUS';
export const UPDATE_ACTIVATION_REQUIRED = 'UPDATE_ACTIVATION_REQUIRED';
export const RESET_MODEL = 'RESET_MODEL';