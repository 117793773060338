import { connect } from 'react-redux';
import { withRouter } from "react-router";
import { createModel, uploadData } from '../../actions/ModelActions';
import { getUploadStatus} from '../../selectors/uiSelectors';
import { getTrainingStatus, getTrainingData, getModelId } from '../../selectors/modelSelectors';
import ModelList from './ModelList';

const mapStateToProps = (state) => {
  return {
    models: [
        {
            name: 'Weather data',
            description: 'Realistic weather data with seasonal changes. Updated with one hour intervals.',
            icon: 'sun',
        },
        {
            name: 'IoT sensor',
            description: 'Time-series measurements form different types of sensors.',
            icon: 'pulse',
        },
        {
          name: 'Social discussion',
          description: 'Social media discussion from multiple users.',
          icon: 'thumbs-up',
      }                
    ]
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    selectAction: (id) => {
      ownProps.history.push(`/model?id=${id}`)
    },
  }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ModelList));